import React from 'react';
import '../styles/contact.scss';
import ContactForm from './contact-form';


export default class Contact extends React.Component {
    
    render = () => {
        console.log(this.props)
        return (
            <section className="contact" id="contact">
                    <h2>Contact</h2>
                    <div className="container">
                        <div className="greeting">
                            <p>Thank you for your interest in my artwork!</p>
                            <p>
                                If you’d like to enquire about the price of any art or discuss a possible commission, or if you need any information related to any of my works, please don’t hesitate to get in touch.
                        </p>
                        </div>
                        <ContactForm subject={this.props.refArt} />
                    </div>
            </section>
        )
    }
}
/*
*/